import React, { Component } from "react";
import {
  MapContainer,
  TileLayer,
  FeatureGroup,
  Polygon,
  Circle,
  Marker,
  Popup,
} from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import { EditControl } from "react-leaflet-draw";
import PropTypes from "prop-types"; // Import PropTypes for prop type validation
import { QIModal, QIInput, QIModalBody, QIModalFooter, QIButton } from "../index";
import "./style.scss";
import sprite from "../../assets/icons.svg";
import { circleToPolygon } from "./helper";
import "leaflet/dist/leaflet.css";
import markerIcon from "leaflet/dist/images/marker-icon.png";

/**
 * A component to display a map where users can draw shapes like rectangles, circles, and polygons.
 */
class QIGeofenceMap extends Component {
  state = {
    coordinates: null, // State to hold the transformed coordinates
    circleRadius: null, // State to hold the transformed Circleradius
    type: null, // State to hold the transformed types
    isNew: this.props.isNew, // State to check new drawing
    isModalOpen: false, //To open alert modal if user editing the map
    coordinatesSearch: "", // Coordinate search string
    searchError: null, //Error in search
    markerCoordinate: null, //Marker coordinate
  };

  /**
   * React lifecycle method that triggers when props are updated.
   * Checks if the coordinates prop has changed and updates the state accordingly.
   * @param {object} prevProps - The previous props of the component.
   */

  componentDidUpdate(prevProps) {
    if (this.props.coordinates !== prevProps.coordinates) {
      const { type, coordinates, radius } = this.props.coordinates;
      if (type === "Polygon") {
        const transformedCoordinates = coordinates[0].map((cord) => [cord[1], cord[0]]);
        this.setState({ coordinates: transformedCoordinates, type });
        // Calculate the center of the geofence
        const center = transformedCoordinates.reduce(
          (accumulator, point) => {
            accumulator[0] += point[0]; // Add the latitude
            accumulator[1] += point[1]; // Add the longitude
            return accumulator;
          },
          [0, 0] // Initial values for latitude and longitude sums
        );
        center[0] /= transformedCoordinates.length; // Calculate average latitude
        center[1] /= transformedCoordinates.length; // Calculate average longitude

        // Pan the map to the center of the geofence
        this.mapRef && this.mapRef.panTo(center);
      }
      if (type === "Circle") {
        const transformedCoordinates = coordinates[0].map((cord) => [cord[0], cord[1]]);

        this.setState(
          { coordinates: transformedCoordinates, radius, type },
          // Pan the map to the center of the geofence
          this.panToCircleCenter
        );
      }
    }
  }

  panToCircleCenter = () => {
    const { coordinates, type } = this.state;
    if (type === "Circle" && coordinates && coordinates.length > 0) {
      this.mapRef && this.mapRef.panTo(coordinates[0]);
    }
  };

  handleCoordinatesSearchChange = (e) => {
    if (!e.target.value) {
      this.setState({ searchError: null });
    }
    this.setState({ coordinatesSearch: e.target.value });
  };

  handleSearchByCoordinates = (e) => {
    e.preventDefault();
    const coordinates = this.state.coordinatesSearch.split(",");
    if (coordinates.length !== 2 || isNaN(coordinates[0]) || isNaN(coordinates[1])) {
      this.setState({
        searchError: "Invalid coordinates. Please enter in the format: lat, long",
      });
      return;
    }

    const lat = parseFloat(coordinates[0]);
    const lng = parseFloat(coordinates[1]);

    // Validate latitude and longitude ranges
    if (lat < -90 || lat > 90 || lng < -180 || lng > 180) {
      this.setState({
        searchError:
          "Invalid coordinates. Latitude must be between -90 and 90, and longitude must be between -180 and 180.",
      });
      return;
    }

    // Update markerCoordinate when valid coordinates are found
    this.setState({
      markerCoordinate: [lat, lng],
      searchError: null, // Clear any search error
    });
    this.mapRef && this.mapRef.panTo([lat, lng]);
  };

  /**
   * Formats an array of Leaflet LatLng points to [lng, lat] format.
   * @param {array} arr - Array of Leaflet LatLng points.
   * @returns {array} - Array of [lng, lat] formatted points.
   */
  formatCoordinates = (arr) => {
    const geoMetryCoordinates = arr.map((point) => [point.lng, point.lat]);
    // Add the first coordinate to the end to close the polygon
    geoMetryCoordinates.push([...geoMetryCoordinates[0]]);

    return geoMetryCoordinates;
  };

  /**
   * Generates points for a circle based on center, radius, and number of points.
   * @param {object} center - Center coordinates of the circle.
   * @param {number} radius - Radius of the circle.
   * @param {number} numPoints - Number of points to generate.
   * @returns {array} - Array of points for the circle.
   */

  /**
   * Handles the creation of drawn shapes (circle, rectangle, polygon).
   * Converts the shape's coordinates to the desired format and updates the state.
   * @param {object} e - Event object containing information about the drawn shape.
   */
  handleDrawCreated = (e) => {
    const { layerType, layer, type, layers } = e;
    this.props.setError(null); // Replace setError with the appropriate prop setter function

    switch (layerType || type) {
      case "circle":
        // Handle circle drawing
        const circleCenter = layer.getLatLng();
        const circleRadius = layer.getRadius();
        //const numCirclePoints = 36; // Number of points to generate for the circle

        const polyCircleCoords = circleToPolygon(circleCenter, circleRadius);

        const transFormedCircleCoords = [Object.values(circleCenter)];
        transFormedCircleCoords.push(transFormedCircleCoords[0]);
        const geoJSONCircle = {
          coordinates: [transFormedCircleCoords],
          type: "Circle",
          polyCircleCords: [polyCircleCoords],
          radius: circleRadius,
        };
        this.props.setCoordinates((prev) => {
          return { ...prev, ...geoJSONCircle };
        });

        break;
      case "rectangle":
        // Handle rectangle drawing
        const rectanglePoints = layer._latlngs[0];
        const rectangleCoordinates = this.formatCoordinates(rectanglePoints);

        const geoJSONRectangle = {
          coordinates: [rectangleCoordinates],
          type: "Polygon",
        };

        this.props.setCoordinates((prev) => {
          return { ...prev, ...geoJSONRectangle };
        });

        break;
      case "polygon":
        // Handle polygon drawing

        const polygonPoints = layer.editing.latlngs[0][0]; // Get array of LatLng points
        const polygonCoordinates = this.formatCoordinates(polygonPoints);

        const geoJSONPolygon = {
          coordinates: [polygonCoordinates],
          type: "Polygon",
        };

        this.props.setCoordinates((prev) => {
          return { ...prev, ...geoJSONPolygon };
        });

        break;
      //EDIT
      case "draw:edited":
        // Handle shape editing
        if (layers) {
          this.setState({ isModalOpen: true });

          const editedShape = layers.getLayers()[0]; // Get the edited shape layer

          if (editedShape instanceof L.Rectangle) {
            // Handle rectangle editing
            const rectanglePoints = editedShape.getLatLngs()[0];
            const rectangleCoordinates = this.formatCoordinates(rectanglePoints);

            const geoJSONRectangle = {
              coordinates: [rectangleCoordinates],
              type: "Polygon",
            };

            this.props.setCoordinates((prev) => {
              return { ...prev, ...geoJSONRectangle };
            });
          } else if (editedShape instanceof L.Polygon) {
            // Handle polygon editing
            const polygonPoints = editedShape.getLatLngs()[0]; // Get array of LatLng points
            const polygonCoordinates = this.formatCoordinates(polygonPoints);

            const geoJSONPolygon = {
              coordinates: [polygonCoordinates],
              type: "Polygon",
            };

            this.props.setCoordinates((prev) => {
              return { ...prev, ...geoJSONPolygon };
            });
          } else if (editedShape instanceof L.Circle) {
            // Handle circle editing
            const circleCenter = editedShape.getLatLng();
            const circleRadius = editedShape.getRadius();
            //const numCirclePoints = 36; // Number of points to generate for the circle

            const polyCircleCoords = circleToPolygon(circleCenter, circleRadius);

            const transFormedCircleCoords = [Object.values(circleCenter)];
            transFormedCircleCoords.push(transFormedCircleCoords[0]);
            const geoJSONCircle = {
              coordinates: [transFormedCircleCoords],
              type: "Circle",
              radius: circleRadius,
              polyCircleCords: [polyCircleCoords],
            };

            this.props.setCoordinates((prev) => {
              return { ...prev, ...geoJSONCircle };
            });
          }
        }
        break;
      //DELETE
      case "draw:deleted":
        layers.eachLayer((deletedLayer) => {
          const geoJSONDelete = { coordinates: {}, type: null, circleRadius: null };

          // Update state with empty coordinates and type
          this.props.setCoordinates({});

          // Reset markerCoordinate when deleted
          this.setState({ markerCoordinate: null, ...geoJSONDelete });
        });
        break;
      default:
        break;
    }
  };

  handleClose(e) {
    this.setState({
      isModalOpen: false,
    });
  }
  handleModalAction = (editAllowed) => {
    if (editAllowed) {
      // Allow editing,
      // ...
    } else {
      const { type, coordinates } = this.state;

      // Assuming this.cancelButtonRef holds a reference to the cancel button element

      if (type === "Circle" && coordinates && coordinates.length > 0) {
        const { type, coordinates, radius } = this.props.coordinates;
        const transformedCoordinates = coordinates[0].map((cord) => [cord[0], cord[1]]);

        this.setState(
          { coordinates: transformedCoordinates, radius, type },
          // Pan the map to the center of the geofence
          this.panToCircleCenter
        );
      }

      // Reset the map center if it's a Polygon
      if (type === "Polygon" && coordinates && coordinates.length > 0) {
        const center = coordinates.reduce(
          (accumulator, point) => {
            accumulator[0] += point[0]; // Add the latitude
            accumulator[1] += point[1]; // Add the longitude
            return accumulator;
          },
          [0, 0] // Initial values for latitude and longitude sums
        );

        center[0] /= coordinates.length; // Calculate average latitude
        center[1] /= coordinates.length; // Calculate average longitude

        this.mapRef && this.mapRef.panTo(center); // Pan the map to the center of the geofence
      }
    }

    this.setState({ isModalOpen: false });
  };

  // Draw Start
  handleDrawStart(isNew) {
    if (!isNew) {
      this.setState({
        isModalOpen: true,
      });
    }
  }

  handleEditStart = () => {
    this.props.setIsEditing(true);
  };

  handleEditStop = () => {
    this.props.setIsEditing(false);
  };

  render() {
    const { coordinates, radius, type, isNew, searchError, markerCoordinate } = this.state;
    const { error } = this.props; // Get error props
    const greenOptions = { color: "green", fillColor: "green" };

    const markerIconOptions = L.icon({
      iconUrl: markerIcon,
    });

    return (
      <>
        {/* Render error messages */}
        {searchError && <div className="error-message">{searchError}</div>}
        {error && <div className="error-message">{error}</div>}
        {/* Coordinate search box */}
        <section className="geofence-map-container">
          <form
            onSubmit={(e) => e.preventDefault()}
            className="coordinate-search flex items-center mt-4 ml-auto"
          >
            <QIInput
              type="text"
              placeholder="Lat, Lng (1.352, 103.819)"
              value={this.state.coordinatesSearch}
              onChange={this.handleCoordinatesSearchChange}
              className=""
            />
            <QIButton onClick={this.handleSearchByCoordinates} className="qi-btn primary">
              <svg className={`icon`}>
                <use href={`${sprite}#search`}></use>
              </svg>
            </QIButton>
          </form>
          <MapContainer
            className="geofence-map"
            center={[1.3521, 103.8198]}
            zoom={15}
            whenCreated={(mapInstance) => {
              this.mapRef = mapInstance; // Store the map instance for later use
            }}
          >
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            <FeatureGroup ref={(ref) => (this.featureGroup = ref)}>
              {/* Render the Polygon if coordinates are available */}
              {coordinates && type !== "Circle" && coordinates.length > 0 && (
                <Polygon pathOptions={greenOptions} positions={coordinates} />
              )}
              {/* Render the Circle if coordinates are available */}
              {type === "Circle" && coordinates && coordinates.length > 0 && (
                <Circle pathOptions={greenOptions} center={coordinates[0]} radius={radius} />
              )}

              {/* Render Marker */}
              {markerCoordinate && (
                <Marker icon={markerIconOptions} position={markerCoordinate}>
                  <Popup>{markerCoordinate?.join(", ")}</Popup>
                </Marker>
              )}

              {/* EditControl for drawing shapes */}
              <EditControl
                position="topright"
                draw={{
                  rectangle: {
                    shapeOptions: {
                      color: "gray", // Set rectangle color to gray
                    },
                  },
                  circle: {
                    shapeOptions: {
                      color: "gray", // Set circle color to gray
                    },
                  },
                  polygon: {
                    shapeOptions: {
                      color: "gray", // Set polygon color to gray
                    },
                  },
                  polyline: false,
                  marker: false,
                  circlemarker: false,
                }}
                onCreated={this.handleDrawCreated}
                onEdited={this.handleDrawCreated}
                onDeleted={this.handleDrawCreated}
                onEditStart={this.handleEditStart}
                onEditStop={this.handleEditStop}
                edit={{ edit: !this.state.isNew }}
                onDrawStart={() => this.handleDrawStart(isNew)}
                ref={(ref) => (this.drawControlRef = ref)}
              />
            </FeatureGroup>
            {/* Coordinate search box */}
          </MapContainer>
        </section>
        {this.state.isModalOpen && (
          <QIModal className="confirm" show={this.state.isModalOpen} size="small">
            <QIModalBody>
              <h2 className="font-bold mb-3">Confirmation Alert</h2>
              <p>Are you sure want to edit changes, this will override existing geofence</p>
            </QIModalBody>
            <QIModalFooter>
              <QIButton
                onClick={() => this.handleModalAction(false)}
                className="qi-btn secondary md"
                variant="secondary"
                size="sm"
              >
                No
              </QIButton>
              <QIButton
                onClick={() => this.handleModalAction(true)}
                size="sm"
                variant="danger"
                className="qi-btn primary md"
              >
                Yes
              </QIButton>
            </QIModalFooter>
          </QIModal>
        )}
      </>
    );
  }
}

// Prop type validation
QIGeofenceMap.propTypes = {
  coordinates: PropTypes.object.isRequired, // Coordinates data for the drawn shape
  setCoordinates: PropTypes.func.isRequired, // Function to set the coordinates state
  error: PropTypes.string, // Define the error prop
  setError: PropTypes.func.isRequired, // Define the error prop setter function
};

export { QIGeofenceMap };
