import React, { useState } from "react";
import { MPSharedTable, MPSharedDeleteModal, MPSharedHeader } from "../Shared";
import { useSearchQuery, usePagination, useDelete, useQuery } from "../../../hooks";
import { useGetDeviceGroupsQuery, useDeleteDeviceGroupMutation } from "../services";
import { DeviceGroupForm } from "./form";
import { DeviceGroupCsvUploadForm } from "./csvUploadForm";
import { Link } from "react-router-dom";
import { getLocalizedString } from "../../../shared/translation";
import { FMDPStorage } from "../../../shared/helper";

export const DeviceGroupsContainer = () => {
  const [idToDelete, setIdToDelete] = useState(null);
  const [idToEditOrCsvUpload, setIdToEditOrCsvUpload] = useState(null);
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [showCsvUploadModal, setShowCsvUploadModal] = useState(false);
  const [showCsvUploadForm, setShowCsvUploadForm] = useState(false);

  const isCreatedByReseller = FMDPStorage?.get("created-by-reseller");
  const appFeatures = FMDPStorage?.get("app_features");

  const isCreateDeviceEnabled = appFeatures?.Devices && appFeatures?.Devices?.create !== undefined;

  const { page, setPage, perPage, pagination } = usePagination();

  const { setSimpleSearch, q } = useSearchQuery({
    page,
    setPage,
    simpleSearchKey: "name_cont",
  });

  const queryParams = {
    page: page,
    per_page: perPage,
    order_by: "created_at",
    order_dir: "desc",
  };

  const query = useQuery();
  let filterHeader = "";
  const filters = [
    {
      label:
        " " +
        getLocalizedString("event_configuration_plan_lowercase", " event configuration plan") +
        ":",
      key: "event_handling_plan_id",
    },
  ];

  filters.map((filter) => {
    if (query.get(filter.key)) {
      queryParams[filter.key] = query.get(filter.key);
      filterHeader = `${filter.label} ${query.get("name")}`;
    }
  });

  const {
    data = {
      total_count: 0,
      data: [],
    },
    error,
    isFetching,
    isSuccess,
  } = useGetDeviceGroupsQuery({
    ...queryParams,
    q,
  });

  const {
    deleteItem: deleteDeviceGroup,
    deleteErrorMsg,
    setDeleteErrorMsg,
  } = useDelete({
    deleteMutation: useDeleteDeviceGroupMutation,
    closeModal: () => setShowDeleteWarning(false),
  });

  const headers = [
    {
      label: getLocalizedString("name", "Name"),
      key: "name",
      className: "name",
    },
    {
      label: getLocalizedString("devices", "Devices"),
      nestedValue: true,
      className: "devices text-center",
      getNestedValue: ({ resources_count, id, name }) => {
        return (
          <>
            {resources_count > 0 ? (
              <Link
                to={`/management_portal/devices/list?device_group_id=${id}&name=${name}`}
                style={{ textDecoration: "none" }}
              >
                {resources_count}
              </Link>
            ) : (
              <span>0</span>
            )}
          </>
        );
      },
    },
    {
      label: getLocalizedString("added_on", "Added On"),
      key: "created_at",
      className: "date",
    },
    {
      label: getLocalizedString("added_by", "Added By"),
      key: "created_by",
      className: "added-by",
    },
  ];

  const onEdit = (data) => {
    setShowForm(true);
    setIdToEditOrCsvUpload(data.id);
  };

  const onUploadCsv = (data) => {
    setShowCsvUploadModal(true);
    setIdToEditOrCsvUpload(data.id);
  };

  const onDelete = (data) => {
    setShowDeleteWarning(true);
    setIdToDelete(data.id);
  };

  const onCreate = () => {
    setShowForm(true);
    setIdToEditOrCsvUpload(null);
  };

  const uploadCsvHandler = () => {
    setShowCsvUploadForm(true);
  };

  const getDeleteItemName = (id) => {
    let name = "";
    const index = data?.data.findIndex((item) => item.id === id);

    if (index >= 0) {
      name = data.data[index].name;
    }
    return name;
  };

  const isUploadCsv = () => {
    if (!isCreateDeviceEnabled && isCreatedByReseller) {
      return false;
    }
    return true;
  };

  return (
    <>
      <article className="main-container">
        <MPSharedHeader
          groupName="group"
          resourceName="Groups"
          createNewHandler={onCreate}
          heading={getLocalizedString("groups", "Groups")}
          filterText={
            filterHeader && `${getLocalizedString("filtered_by", "Filtered by")} ${filterHeader}`
          }
          handleSearchKey={setSimpleSearch}
          simpleSearchPlaceholder={getLocalizedString("search_by_name", "Search by Name")}
          uploadCsvHandler={isUploadCsv()}
        />

        <MPSharedTable
          resourceName="Groups"
          data={data?.data || []}
          error={error}
          isLoading={isFetching}
          isSuccess={isSuccess}
          headers={headers}
          onEdit={onEdit}
          onDelete={onDelete}
          onUploadCsv={isUploadCsv() && onUploadCsv}
          pagination={{ ...pagination, count: data.total_count }}
          skipClientId
          className="device-groups"
          auditResource="DeviceGroup"
          auditKey="name"
        />
      </article>

      {showCsvUploadModal && (
        <DeviceGroupCsvUploadForm
          idToEditOrCsvUpload={idToEditOrCsvUpload}
          onClose={() => setShowCsvUploadModal(false)}
        />
      )}

      {showCsvUploadForm && (
        <DeviceGroupCsvUploadForm onClose={() => setShowCsvUploadForm(false)} />
      )}

      {showForm && (
        <DeviceGroupForm
          idToEditOrCsvUpload={idToEditOrCsvUpload}
          onClose={() => setShowForm(false)}
        />
      )}

      <MPSharedDeleteModal
        show={showDeleteWarning}
        entityName={getLocalizedString("device_group", "Device Group")}
        error={deleteErrorMsg}
        itemName={getDeleteItemName(idToDelete)}
        onHide={() => {
          setDeleteErrorMsg("");
          setShowDeleteWarning(false);
        }}
        onDelete={() => deleteDeviceGroup({ id: idToDelete })}
      />
    </>
  );
};
